import {Howl, Howler} from 'howler';

class SFX {
    constructor(webm, mp3, volumeLevel){ 
        this.sound = new Howl({
            src: [webm, mp3],
            volume: volumeLevel
        });
    }

    play(){
        if (this.sound !== null) {
            this.sound.play();
        }
    }
}

export default SFX;