import {Howl} from 'howler';

class Sample {
    constructor(path){ 
        this.sound = new Howl({
            src: [path],
            volume: 0.85
        });  
    }

    play(){  
        if (this.sound.playing()) {
            return;
        } else {
            this.sound.play();
        }
    }

    pause(){  
        this.sound.pause(); 
    }

    stop(){  
        this.sound.stop(); 
    }

    assignEvents(playElement, pauseElement, stopElement){
        document.getElementById(playElement).addEventListener('click', ()=>{
            this.play();
        });

        document.getElementById(pauseElement).addEventListener('click', ()=>{
            this.pause();
        });

        document.getElementById(stopElement).addEventListener('click', ()=>{
            this.stop();
        });
    }

    updateProgress(HTMLElement){ 
        let progress = this.sound.seek()/this.sound.duration();
        document.getElementById(HTMLElement).style.width = (progress * 100) + '%'; 
    }
 
}

export default Sample;