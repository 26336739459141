const textContent = {

    intro_button:{

        english: 'Click here pirate!',
        fr: "C'est par ici moussaillon !",

    },

    instructions:{

        explore_title_fr: "Comment explorer ?",
        explore_text_fr: "Utiliser la barre de défilement ou la roulette de la souris pour faire monter ou descendre le canvas et explorer le contenu des symboles.",
        buy_title_fr: "Acheter l'album digital",
        buy_text_fr: "Cliquer sur point clignotant à côté du coffre vous redigirera vers une page où vous pourrez procéder a l'achat de lalbum.", 

        explore_title_eng: "How to explore the multiverse ?",
        explore_text_eng: "Use the scroll bar or mouse wheel to move the canvas up and down and explore the symbols contents.",
        buy_title_eng: "To buy the digital album",
        buy_text_eng: "If you click on the flashing dot next to the treasure box, you will be redirected to a page where you can purchase the album.", 
        
        confirm_button_fr: "J'ai compris !",
        confirm_button_eng: "Got it!",
    },

    fullscreen: {
        fullscreen_text_fr: "Pour une meilleure expérience sur mobile, <br>veuillez activer le mode plein écran de votre navigateur.",
        fullscreen_text_eng: "For a better experience in mobile, <br>enable fullscreen mode on your browser.",
        fullscreen_button_fr: "Afficher en plein écran",
        fullscreen_button_eng: "View in fullscreen",
        fullscreen_runtime_fr: "Veuillez réactiver le mode plein écran pour continuer.",
        fullscreen_runtime_eng: "Enable the fullscreen mode !",
    }
}

export default class LanguageManager {

    constructor(frBtn, englishBtn){ 
        this.frBtn = frBtn;
        this.englishBtn = englishBtn;
    }

    init(){
        document.getElementById(this.frBtn).addEventListener('click', ()=>{
            this.getText('buttonEnter').innerHTML = textContent.intro_button.fr;
            this.getText('explore-title').textContent = textContent.instructions.explore_title_fr;
            this.getText('explore-text').textContent = textContent.instructions.explore_text_fr;
            this.getText('buy-title').textContent = textContent.instructions.buy_title_fr;
            this.getText('buy-text').textContent = textContent.instructions.buy_text_fr;
            this.getText('displayCanvasButton').textContent = textContent.instructions.confirm_button_fr;
            this.getText('fullscreen-text').textContent = textContent.fullscreen.fullscreen_text_fr;
            this.getText('fullscreen-runtime-text').textContent = textContent.fullscreen.fullscreen_runtime_fr;
        });
        
        document.getElementById(this.englishBtn).addEventListener('click', ()=>{ 
            this.getText('buttonEnter').innerHTML = textContent.intro_button.english;
            this.getText('explore-title').textContent = textContent.instructions.explore_title_eng;
            this.getText('explore-text').textContent = textContent.instructions.explore_text_eng;
            this.getText('buy-title').textContent = textContent.instructions.buy_title_eng;
            this.getText('buy-text').textContent = textContent.instructions.buy_text_eng;
            this.getText('displayCanvasButton').textContent = textContent.instructions.confirm_button_eng;
            this.getText('fullscreen-text').textContent = textContent.fullscreen.fullscreen_text_eng;
            this.getText('fullscreen-runtime-text').textContent = textContent.fullscreen.fullscreen_runtime_eng;
        });
    }

    getText(element){
        return document.getElementById(element);
    }
}