import * as PIXI from 'pixi.js' 
 
class Animation {
    constructor(tex_array, container, app){ 
 
        this.tex_array = tex_array;
        this.loadedSprites = []; 

        for (let i = 0; i < this.tex_array.length; i++) {
            let tex = new PIXI.Sprite(this.tex_array[i]);
            tex.width = app.view.width;
            tex.height = app.view.width;
            tex.alpha = 0;
            container.addChild(tex);
            this.loadedSprites.push(tex);
        }
        
        this.loadedSprites[0].alpha = 1;
    } 
 
    updateSize(newWidth){
        for (let i = 0; i < this.loadedSprites.length; i++) {
            this.loadedSprites[i].width = newWidth;
            this.loadedSprites[i].height = newWidth; 
        } 
    }

    switchFrames(frameCounter){
        //if (this.loadedSprites.length > 1) {
            if (frameCounter == 0) {
                this.loadedSprites[0].alpha = 1;
            } if (frameCounter > 0) {
                this.loadedSprites[frameCounter - 1].alpha = 0;
                this.loadedSprites[frameCounter].alpha = 1;
            }
        //}
    }

    hideAllFrames(){
        for (let i = 0; i < this.loadedSprites.length; i++) {
            this.loadedSprites[i].alpha = 0;  
        } 
    }
 

}

export default Animation;