// Assets Import - Sprites
import track1 from "./assets/sprites/tracks_icons/1.png"
import track2 from "./assets/sprites/tracks_icons/2.png"
import track3 from "./assets/sprites/tracks_icons/3.png"
import track4 from "./assets/sprites/tracks_icons/4.png"
import track5 from "./assets/sprites/tracks_icons/5.png"
import track6 from "./assets/sprites/tracks_icons/6.png"
import track7 from "./assets/sprites/tracks_icons/7.png"
import track8 from "./assets/sprites/tracks_icons/8.png"
import track9 from "./assets/sprites/tracks_icons/9.png"
import track10 from "./assets/sprites/tracks_icons/10.png" 
import triggerIcon from "./assets/sprites/trigger.png" 
import logo from "./assets/sprites/iscience-logo.png"
import gradient from "./assets/sprites/gradient.png"

// Assets Import - Spritesheet 
import vault00 from "./assets/animations/vault/vault_00.png" 
import vault01 from "./assets/animations/vault/vault_01.png" 
import vault02 from "./assets/animations/vault/vault_02.png" 

import cliff00 from "./assets/animations/cliff/cliff_00.png" 
import cliff01 from "./assets/animations/cliff/cliff_01.png" 
import cliff02 from "./assets/animations/cliff/cliff_02.png" 

// Assets Import - Audio
import chest_webm from './assets/audio/chest_.webm'
import chest_mp3 from './assets/audio/chest_.mp3'
import monster_webm from './assets/audio/monster.webm'
import monster_mp3 from './assets/audio/monster.mp3'


//Libs + Frameworks
import 'simplebar'; // or "import SimpleBar from 'simplebar';" if you want to use it manually.
import 'simplebar/dist/simplebar.css';
import * as PIXI from 'pixi.js'
import Data from "./js/AssetsData.js"
import Symbol from "./js/Symbol"
import "./scss/main.scss" 
import Background from "./js/Background"
import Animation from "./js/Animation"
import gsap from 'gsap'
import PixiPlugin from 'gsap/PixiPlugin'
import * as ismobile from 'is-mobile'
import SFX from "./js/SFX"
import {Howler} from 'howler'
import Sample from './js/Sample'

import LanguageManager from './js/LanguageManager.js'
let languageManager = new LanguageManager('lang_FR', 'lang_ENG');
languageManager.init();

//Samples
// Import samples.
import trackSample_1 from './assets/samples/_1.mp3'
import trackSample_2 from './assets/samples/_2.mp3'
import trackSample_3 from './assets/samples/_3.mp3'
import trackSample_4 from './assets/samples/_4.mp3'
import trackSample_5 from './assets/samples/_5.mp3'
import trackSample_6 from './assets/samples/_6.mp3'
import trackSample_7 from './assets/samples/_7.mp3'
import trackSample_8 from './assets/samples/_8.mp3'
import trackSample_9 from './assets/samples/_9.mp3'
import trackSample_10 from './assets/samples/_10.mp3'

// Push all assets into this empty array
let tracks_samples_paths = [];

tracks_samples_paths.push(
    trackSample_1, 
    trackSample_2, 
    trackSample_3, 
    trackSample_4,
    trackSample_5,
    trackSample_6,
    trackSample_7,
    trackSample_8,
    trackSample_9,
    trackSample_10
    );

let samples = [];

// Create Sample Instances
for (let i = 0; i < tracks_samples_paths.length; i++) {
    let sample = new Sample(tracks_samples_paths[i]);
    samples.push(sample);
}

gsap.registerPlugin(PixiPlugin);
PixiPlugin.registerPIXI(PIXI);

let Application = PIXI.Application;
let loader = PIXI.Loader.shared;
let Sprite = PIXI.Sprite;

// Basic PIXI's object
let app, root, interaction;
let vignette_top;
let vignette_bottom;
let background;
let scroll, scrollBar;
let vaultTrigger, galleryTrigger;
let vault_anim, cliff_anim;
let chestSFX = new SFX(chest_webm, chest_mp3, 0.45);
let cliffSFX = new SFX(monster_webm, monster_mp3, 0.5);

//Icons Arrays
let textures = [];
let symbols = [];


//Check if fullscreen exit 
document.addEventListener('fullscreenchange', (e)=>{
    if(ismobile.isMobile()) {
        if(!document.fullscreenElement) {
            console.log('fullsrc exit on mobile'); 
            getID('fullscreenRuntime').style.display = 'flex'; 
        }
    }
}, false); 

getID('fullscreenRuntime').addEventListener('click', ()=>{
    //Get the documentElement (<html>) to display the page in fullscreen
    var elem = document.documentElement;
    
    if (elem.requestFullscreen) {
        elem.requestFullscreen();
    } else if (elem.webkitRequestFullscreen) { 
        elem.webkitRequestFullscreen();
    } else if (elem.msRequestFullscreen) { 
        elem.msRequestFullscreen();
    }

    getID('fullscreenRuntime').style.display = 'none'; 
});


if (!ismobile.isMobile()) {
    //Desktop 
    getID('fullscreen_btn').style.display = 'none';
    getID('loadAssets_btn').style.display = 'initial';  
    console.log('not a mobile device !');
} else {
    //Mobile devices
    getID('fullscreen_btn').style.display = 'initial' 
    getID('loadAssets_btn').style.display = 'none'; 

    //Check if it should be rotated
    if (window.innerHeight > window.innerWidth) {
        console.log("Rotate please !");
        getID('landscapeRequest').style.display = 'flex';
    } else {
        getID('landscapeRequest').style.display = 'none';
    }
}

//Animate logo
gsap.fromTo("#ndeyesan-logo", {opacity: 0, y: -30}, {opacity: 1, y: 0, duration: 3});

//Hide "Display Canvas" button.
getID('displayCanvas').style.display = 'none';

//Assign button for displaying the canvas
getID('displayCanvas').addEventListener('click', ()=>{
    getID('instructions').style.display = 'none';
    getID('lang').style.display = 'none';
});

//Assign button for loading assets !
getID('loadAssets_btn').addEventListener('click', loadAssets);

//Assign button for fullscreen mode ! Mobile-only
getID('fullscreen_btn').addEventListener('click', ()=>{
    //Get the documentElement (<html>) to display the page in fullscreen
    var elem = document.documentElement;
 
    if (elem.requestFullscreen) {
        elem.requestFullscreen();
    } else if (elem.webkitRequestFullscreen) { 
        elem.webkitRequestFullscreen();
    } else if (elem.msRequestFullscreen) { 
        elem.msRequestFullscreen();
    }

    getID("loadAssets_btn").style.display = 'initial';
    getID("fullscreen_btn").style.display = 'none';

    //Animate logo
    gsap.fromTo("#ndeyesan-logo", {opacity: 0, y: -30}, {opacity: 1, y: 0, duration: 3});
});
 
 
function getID(HTMLElementName){
    return document.getElementById(HTMLElementName);
}

//Init progress - get the html element
let progressCount = 0;
let progress = document.getElementById('progress');
 

//Load all assets
function loadAssets() { 

    //Either on mobile or not hide the "fullscreen" window
    getID('fullscreenRequest').style.display = 'none';

    //Show "Instructions" window
    getID('instructions').style.display = 'flex';
    loader 
    .add(track1)
    .add(track2)
    .add(track3)
    .add(track4)
    .add(track5)
    .add(track6)
    .add(track7)
    .add(track8)
    .add(track9)
    .add(track10)
    .add(triggerIcon)
    .add(vault00)
    .add(vault01)
    .add(vault02)
    .add(cliff00)
    .add(cliff01)
    .add(cliff02)
    .add(logo)
    .add(gradient) 
    .load(Init);

    loader.onProgress.add((loader, resource)=>{
        getID('loadingProgress').innerHTML = "Chargement... " + Math.round(loader.progress) + '%'
        if(loader.progress >= 100){
            //Show "Display Canvas" button.
            getID('displayCanvas').style.display = 'initial';
            //Hide "loading text" button.
            getID('loadingProgress').style.display = 'none';
        }
    });

}

// Manage players & samples
let players_ = [];
let soundProgressBars = [];

//Gather player UI elements
for (let i = 0; i < tracks_samples_paths.length + 1; i++) {
    let element = document.getElementById('howl_player_' + i);
    players_.push(element); 
}

for (let i = 0; i < tracks_samples_paths.length; i++) {
    let element = document.getElementById('progress-bar-' + i);
    element.style.display = 'none'
    soundProgressBars.push(element); 
} 

// Assign events listeners
for (let i = 0; i < tracks_samples_paths.length; i++) {
    samples[i].assignEvents('playBtn_' + i, 'pauseBtn_' + i, 'stopBtn_' + i);
}

document.getElementById('close_btn').onclick = function(){ 
    document.getElementById("content-wrapper").style.display = "none"; 
    Howler.stop(); 
    for (let i = 0; i < players_.length; i++) {
        players_[i].style.display = 'none';
    }
    for (let i = 0; i < soundProgressBars.length; i++) {
        soundProgressBars[i].style.display = 'none';
    }
}



//#region Gallery Config

let gallery = getID('gallery');
let galleryList = [];  

gallery.children.forEach(element => {
    if (element.localName == "div") { 
        element.style.display = "none";
        galleryList.push(element);
    }
});

let index = 0;

galleryList[index].style.display = "initial";

function gallery_next_photo(){  
    if (index <= 0) {
        index++;
        galleryList[index - 1].style.display = "none";
        galleryList[index].style.display = "initial";
    } else if (index > 0 ) {
        if (index == (galleryList.length - 1)) {
            index = 0;
            //Reset all display
            for (let e = 0; e < galleryList.length; e++) {
                galleryList[e].style.display = "none";
            }
            galleryList[index].style.display = "initial"; 
        } else {
            index++;
            galleryList[index - 1].style.display = "none";
            galleryList[index].style.display = "initial";
        }

    } 
}

function gallery_prev_photo(){
    if (index > 0) {
        index--;
        galleryList[index + 1].style.display = "none";
        galleryList[index].style.display = "initial";
    } else if (index <= 0 ) {
        if (index == 0) {
            index = galleryList.length-1;
            //Reset all display
            for (let e = 0; e < galleryList.length; e++) {
                galleryList[e].style.display = "none";
            }
            galleryList[index].style.display = "initial"; 
        } else {
            index--;
            galleryList[index + 1].style.display = "none";
            galleryList[index].style.display = "initial";
        }

    } 
}

getID('gallery_next_photo').addEventListener("click", ()=>{
    gallery_next_photo();
});

getID('gallery_prev_photo').addEventListener("click", ()=>{
    gallery_prev_photo();
});

getID('gallery-close').addEventListener('click', ()=>{ 
    getID('gallery').style.display = 'none';
    getID('gallery-close').style.display = 'none';
});

getID('help').addEventListener('click', ()=>{
    getID('instructions').style.display = 'flex';
});

getID('credits_btn').addEventListener('click', ()=>{
    getID('credits').style.display = 'flex';
});

getID('credits_close_btn').addEventListener('click', ()=>{
    getID('credits').style.display = 'none';
});

//#endregion

function fit_range( value, r1, r2 ) { 
    return ( value - r1[ 0 ] ) 
    * 
    ( r2[ 1 ] - r2[ 0 ] ) 
    / 
    ( r1[ 1 ] - r1[ 0 ] ) 
    + 
    r2[ 0 ];
}



function Init(){ 
   
    app = new Application({
        width: window.innerWidth,
        height: window.innerHeight,
        antialias: true,
        backgroundAlpha: true,
        resolution: 1,
        resizeTo: window, 
    }); 
    
    interaction = app.renderer.plugins.interaction; 

    //Enable mouse interactions on the stage.
    app.stage.interactive = true;

    root = new PIXI.Container();  

    // Pointer Events 
    let dragging = false;
    let data;

    
    background = new Background(root, app);
    
    

    let cliff_anim_array = [
        loader.resources[cliff00].texture,  
        loader.resources[cliff01].texture,
        loader.resources[cliff02].texture,
    ];

    cliff_anim = new Animation(cliff_anim_array, root, app);
    
    let iscience_logo = new PIXI.Sprite(loader.resources[logo].texture);
    root.addChild(iscience_logo);
    iscience_logo.width = background.BG_SPRITE.width;
    iscience_logo.height = background.BG_SPRITE.height;

    galleryTrigger = new TriggerPoint(15, 35, 2, 3);
    galleryTrigger.icon.on('pointerdown', function(){
        if (f !== 0) {
            f = 0;
        }
        animID = galleryTrigger.id;
        cliffSFX.play();
        cliffSFX.sound.on('end', function(){
            getID('gallery').style.display = 'flex';
            getID('gallery-close').style.display = 'initial';
            animID = 0;
        });
    }); 

    let vault_anim_array = [
        loader.resources[vault00].texture,  
        loader.resources[vault01].texture,
        loader.resources[vault02].texture,
    ];
    
    vault_anim = new Animation(vault_anim_array, root, app);

    vaultTrigger = new TriggerPoint(62.5, 62.5, 1, 3);
    vaultTrigger.icon.on('pointerdown', function(){ 
        if (f !== 0) {
            f = 0;
        }
        chestSFX.play();
        chestSFX.sound.on('end', function(){
            window.open('http://www.ndeyesan.com/lebutin', "_self");
        });
        animID = vaultTrigger.id;
    });

    app.stage.addChild(root); 

    vignette_top = new PIXI.Sprite(loader.resources[gradient].texture);
    vignette_top.height = app.view.height * 0.35;
    vignette_top.alpha = 0.7;
    app.stage.addChild(vignette_top);

    vignette_bottom = new PIXI.Sprite(loader.resources[gradient].texture);
    vignette_bottom.height = -app.view.height * 0.35;
    vignette_bottom.alpha = 0.7;
    vignette_bottom.y = app.view.height;  
    app.stage.addChild(vignette_bottom);
    
    let endClamp = -((background.BG_SPRITE.height + root.y) - app.view.height);

    let scrollBarWidth = 1;
    let scrollBeginPoint = app.view.height * 0.20;
    let scrollEndPoint = app.view.height - (app.view.height * 0.20);

    scrollBar = new PIXI.Graphics();
    scrollBar.beginFill(0xcfb278, 1);
    scrollBar.drawRect(0,0,scrollBarWidth,app.view.height);
    scrollBar.endFill();
    scrollBar.x = app.view.width - 50;
    scrollBar.y = 0;
    

    scroll = new PIXI.Graphics();
    scroll.beginFill(0xFcfb278, 1);
    scroll.drawCircle(0,0,16);
    scroll.x = scrollBar.x;
    scroll.y = scrollBeginPoint;
    scroll.endFill(); 
    
    app.stage.addChild(scroll);
    app.stage.addChild(scrollBar);

    scroll.interactive = true;

    scroll
        .on('pointerdown', onDragStart)
        .on('pointerup', onDragEnd)
        .on('pointerupoutside', onDragEnd)
        .on('pointermove', onDragMove);

    function onDragStart(event) {
        // store a reference to the data
        // the reason for this is because of multitouch
        // we want to track the movement of this particular touch
        data = event.data; 
        scroll.dragging = true; 
    }
    
    function onDragEnd() {
        scroll.dragging = false;
        // set the interaction data to null
        data = null;
        
        if (scroll.y < scrollBeginPoint) {
            scroll.dragging = false;
            scroll.y = scrollBeginPoint + (scrollBeginPoint * 0.05);
        } else if (scroll.y > scrollEndPoint) {
            scroll.dragging = false;
            scroll.y = scrollEndPoint - (scrollEndPoint * 0.05);
        }   
    }
    
    function onDragMove() {
        if (this.dragging) {
            const newPosition = data.getLocalPosition(scroll.parent);
            //scroll.x = newPosition.x; 
            scroll.y = newPosition.y;
            if (scroll.y >= scrollBeginPoint && scroll.y <= scrollEndPoint) {
                root.y = -fit_range(scroll.y, [scrollBeginPoint,scrollEndPoint], [0,background.BG_SPRITE.height - app.view.height]); 
            }
        }
    } 

    document.addEventListener('wheel', (e)=>{
        if(Math.sign(e.deltaY) > 0){ 
            if (scroll.y >= scrollBeginPoint && scroll.y <= scrollEndPoint) {
                scroll.y += 5; 
                root.y = -fit_range(scroll.y, 
                    [scrollBeginPoint,scrollEndPoint], 
                    [0,background.BG_SPRITE.height - app.view.height]);

                //Prevent the scroll to go over
                if (scroll.y < scrollBeginPoint) {
                    //alert('Out of bounds of BEGIN!');
                    scroll.y = scrollBeginPoint;
                    root.y = -fit_range(scroll.y, 
                        [scrollBeginPoint,scrollEndPoint], 
                        [0,background.BG_SPRITE.height - app.view.height]);
                } else if (scroll.y > scrollEndPoint) {
                    //alert('Out of bounds of END!');
                    scroll.y = scrollEndPoint;
                    root.y = -fit_range(scroll.y, 
                        [scrollBeginPoint,scrollEndPoint], 
                        [0,background.BG_SPRITE.height - app.view.height]);
                } 

            } 
        } else if(Math.sign(e.deltaY) < 0){  
            if (scroll.y >= scrollBeginPoint && scroll.y <= scrollEndPoint) {
                scroll.y -= 5;
                root.y = -fit_range(scroll.y, 
                    [scrollBeginPoint,scrollEndPoint], 
                    [0,background.BG_SPRITE.height - app.view.height]);
                
                //Prevent the scroll to go over
                if (scroll.y < scrollBeginPoint) {
                    //alert('Out of bounds of BEGIN!');
                    scroll.y = scrollBeginPoint;
                    root.y = -fit_range(scroll.y, 
                        [scrollBeginPoint,scrollEndPoint], 
                        [0,background.BG_SPRITE.height - app.view.height]);
                } else if (scroll.y > scrollEndPoint) {
                    //alert('Out of bounds of END!');
                    scroll.y = scrollEndPoint;
                    root.y = -fit_range(scroll.y, 
                        [scrollBeginPoint,scrollEndPoint], 
                        [0,background.BG_SPRITE.height - app.view.height]);
                } 
        } 
        }
    });
    

    //Assign URLs (since they are nulls !) into Data Array
    textures.push(
        track1, 
        track2, 
        track3, 
        track4, 
        track5,
        track6,
        track7,
        track8,
        track9,
        track10
    );

    for (let i = 0; i < Data.length; i++) {
        Data[i].url = textures[i];
    }
   
    //Create Symbol Object & push them on SYMBOLS[]
    for (let i = 0; i < Data.length; i++) {
        let symbol = new Symbol(Data[i].url, root, Data[i].id); 
        symbols.push(symbol);
    }

    //Update progress !!!
    symbols.forEach(s => {
        s._sprite.on('pointerdown', ()=>{
            if (progressCount <= (symbols.length + 1) && !s.alreadyOpened) {
                progressCount++;
                s.alreadyOpened = true;
                document.getElementById('progressCount').textContent = progressCount + '/' + symbols.length;
                console.log(s.alreadyOpened);
            } else {
                return;
            }
        });
    }); 
    
    for (let i = 0; i < symbols.length; i++) {
        symbols[i].setPosition(background.BG_SPRITE, Data[i].pX, Data[i].pY);  
    }  

    CheckInitialSize();

    //Append the canvas to the body
    document.body.appendChild(app.view);   

    //Make the canvas full width
    document.body.style.overflow = 'hidden'
    document.body.style.margin = 0;
    document.body.style.padding = 0;

    if(!app.stage.interactive){
        app.stage.interactive = true;
    }

    //Ticker 
    app.ticker.add(delta => Update(delta)); 

}
 
let desktopWidthRef = 1440; 
let tabletWidthRef = 960;
let phoneWidthRef /* landscape only */ = 450;  



function resize(){ 
    window.addEventListener('resize', (e)=>{ 
        if(ismobile.isMobile()){
             //Check if it should be rotated
            if (window.innerHeight > window.innerWidth) {
                console.log("Rotate please !");
                getID('landscapeRequest').style.display = 'flex';
            } else {
                getID('landscapeRequest').style.display = 'none';
            }
        } else {
            //What to do on desktop ?
            window.location.reload();
        }
    });
}

document.addEventListener("visibilitychange", visibilityChangeHandle);

function visibilityChangeHandle() {
    if(document.visibilityState == "hidden"){
        if (ismobile.isMobile()) {
            window.location.reload();
        }
    }  
}

resize();

//Check 
function CheckInitialSize() {
    if (window.innerWidth < tabletWidthRef) {
        for (let i = 0; i < symbols.length; i++) {
            symbols[i]._sprite.scale.x = 0.55;
            symbols[i]._sprite.scale.y = 0.55;
        }
    }  else if (window.innerWidth > tabletWidthRef) {
        for (let i = 0; i < symbols.length; i++) {
            symbols[i]._sprite.scale.x = 0.7;
            symbols[i]._sprite.scale.y = 0.7;
        }
    } else if (window.innerWidth < phoneWidthRef) {
        for (let i = 0; i < symbols.length; i++) {
            symbols[i]._sprite.scale.x = 0.35;
            symbols[i]._sprite.scale.y = 0.35;
        }
    }
    
}

let animID = 0;

let f = 0;
let timer = 0; 
let timeScale = 20;
 
function Update(delta){
    
    //Update sound progress bar
    for (let i = 0; i < samples.length; i++) {
        samples[i].updateProgress('progress-bar-' + i);
    }

    for (let i = 0; i < symbols.length; i++) {
        symbols[i].spin(Data[i].spin, Data[i].spinSpeed);  
    } 
    vignette_top.width = app.view.width;
    vignette_bottom.width = app.view.width; 
    
    timer += delta;


    switch (animID) {
        case 0: 
            f = 0; 

            for (let i = 0; i < vault_anim.loadedSprites.length; i++) {
                vault_anim.loadedSprites[i].alpha = 0;
                if (i == 0) {
                    vault_anim.loadedSprites[i].alpha = 1;
                    continue;
                }
            }

            for (let i = 0; i < cliff_anim.loadedSprites.length; i++) {
                cliff_anim.loadedSprites[i].alpha = 0;
                if (i == 0) {
                    cliff_anim.loadedSprites[i].alpha = 1;
                    continue;
                }
            }
 
            break;

        case 1: // Vault animation.
            //Reinitialize Frame Counter 
            if (Math.round(timer)%timeScale == 0) { 
                if (f < vaultTrigger.anim_frames_count - 1) {
                    f++;
                    vault_anim.switchFrames(f);
                }
            }
            break;

        case 2: // Cliff animation
            //Reinitialize Frame Counter
            if (Math.round(timer)%timeScale == 0) { 
                if (f < galleryTrigger.anim_frames_count - 1) {
                    f++;
                    cliff_anim.switchFrames(f);
                }
            }
            break;
    
        default:
            break;
    } 
}
 
class TriggerPoint{
    constructor(pX, pY, id, anim_frames_count){
 
        this.pX = pX;
        this.pY = pY;
        this.id = id;
        this.anim_frames_count = anim_frames_count;

        this.c_on_center = new PIXI.Graphics();
        this.c_on_center.beginFill(0xcfb278, 1);
        this.c_on_center.drawCircle(0,0,12);
        this.c_on_center.endFill();

        this.c_on_center.x = background.BG_SPRITE.width * (this.pX/100);
        this.c_on_center.y = background.BG_SPRITE.height * (this.pY/100);
        this.c_on_center.interactive = true;
        this.c_on_center.cursor = 'pointer';

        this.icon = new PIXI.Sprite(loader.resources[triggerIcon].texture);
        this.icon.x = background.BG_SPRITE.width * (this.pX/100);
        this.icon.y = background.BG_SPRITE.height * (this.pY/100);
        this.icon.scale.set(0.25, 0.25);
        this.icon.anchor.set(0.5, 0.5);
        this.icon.interactive = true;
        this.icon.cursor = 'pointer';
        root.addChild(this.icon);

        this.sonar = [];

        //root.addChild( this.c_on_center);

        for (let i = 0; i < 2; i++) {
            let c = new PIXI.Graphics();
            c.lineStyle(1, 0xcfb278, 1);
            c.beginFill(0xcfb278, 0.2);
            c.drawCircle(0,0,25 * i);
            c.endFill(); 

            c.x = this.c_on_center.x;
            c.y = this.c_on_center.y;
    
            root.addChild(c);

            this.sonar.push(c);
    
            gsap.to(c,{
                pixi: {
                    scaleX: 2, scaleY: 2, alpha: 0
                },
                duration: 1, repeat: -1,
            });
        }
        
    }

    updateTriggerPos(){
        this.c_on_center.x = background.BG_SPRITE.width * (this.pX/100);
        this.c_on_center.y = background.BG_SPRITE.height * (this.pY/100);
        for (let i = 0; i < this.sonar.length; i++) {
            const element = this.sonar[i];
            element.x = this.c_on_center.x;
            element.y = this.c_on_center.y;
        }
    }
}