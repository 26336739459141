import cover_background from "../assets/sprites/cover_background.png"
import logo_tex from "../assets/sprites/iscience-logo.png"
import * as PIXI from "pixi.js" 

class Background {
    constructor(container, app){

        this.app = app;
        
        this.BG_SPRITE = PIXI.Sprite.from(cover_background);
        
        // Adding the logo
        this.logo = PIXI.Sprite.from(logo_tex);

        container.addChild(this.BG_SPRITE); 
        //container.addChild(this.logo);

        this.BG_SPRITE.width = this.app.view.width;
        this.BG_SPRITE.height = this.BG_SPRITE.width;

        this.logo.width = this.BG_SPRITE.width;
        this.logo.height = this.BG_SPRITE.width;
    }

    updateSize(newWidth){
        this.BG_SPRITE.width = newWidth;
        this.BG_SPRITE.height = this.BG_SPRITE.width;
        //Update logo size
        this.logo.width = this.BG_SPRITE.width;
        this.logo.height = this.BG_SPRITE.width;
    }
    
}

export default Background;