// Assets Import - Sprites
import track1 from "../assets/sprites/tracks_icons/1.png"
import track2 from "../assets/sprites/tracks_icons/2.png"
import track3 from "../assets/sprites/tracks_icons/3.png"
import track4 from "../assets/sprites/tracks_icons/4.png"
import track5 from "../assets/sprites/tracks_icons/5.png"
import track6 from "../assets/sprites/tracks_icons/6.png"
import track7 from "../assets/sprites/tracks_icons/7.png"
import track8 from "../assets/sprites/tracks_icons/8.png"
import track9 from "../assets/sprites/tracks_icons/9.png"
import track10 from "../assets/sprites/tracks_icons/10.png" 

// Assets BGs
import bg1 from "../assets/backgrounds/_1.png"
import bg2 from "../assets/backgrounds/_2.png"
import bg3 from "../assets/backgrounds/_3.png"
import bg4 from "../assets/backgrounds/_4.png"
import bg5 from "../assets/backgrounds/_5.png"
import bg6 from "../assets/backgrounds/_6.png"
import bg7 from "../assets/backgrounds/_7.png"
import bg8 from "../assets/backgrounds/_8.png"
import bg9 from "../assets/backgrounds/_6.png"
import bg10 from "../assets/backgrounds/_3.png"

let Data = [
    {
        id: 0,
        pX: 50,
        pY: 10, 
        iconURL: track1,
        spin: false,
        spinSpeed: 0,
        url: null,
        title: 'I-Lila',
        lyrics : `
        The night is coming down (I layla)<br>
        Obscurity bring mystery (I layla oh)<br>
        As you’re exploring zair (I layla)<br>
        What you’ll discover is baatin<br>
        Better know where you’re stepping your feet<br>
        Nga wara xam sa bopp<br>
        [Il faut se connaître soi même]<br>
        Gouddi gui jotna tey<br>
        [C’est le moment de la nuit aujourd’hui] So lendëm bi noué tey<br>
        [Si l’obscurité arrive aujourd’hui]<br>
        Nga wara am soutoura<br>
        [Il faut avoir de la réserve]<br>
        Gouddi gui ak beççek<br>
        [la nuit et le petit matin]<br>
        Nga wara xam wute na<br>
        [Sais-tu qu’ils sont vastes ?]<br>
        Ci bitti lamp bi fey na<br>
        [à l’extérieur les lampes sont éteintes] Nga wara leral sa khol<br>
        [Il faut éclairer ton cœur]<br>
        Nga wara leral sa khol<br>
        [Il faut éclairer ton cœur] khol, khol, khol<br>
        Leral sa khol<br>
        Khol, khol, khol<br>
        REF<br>
        I look pon the world<br>
        Look pon the world<br>
        Going through some deep shit Going through some deep shit Look how far we’ve gone<br>
        Blinded by obscurity now<br>
        Blinded by obscurity<br>
        Lord give us faith<br>
        Lord give us faith<br>
        Shed your light and set us free now Shed your light and set us free Lord, Lord, Lord, Lord, Lord<br>
        Gouddi gouddi gouddi amna borom<br>
        [La nuit a un patron]<br>
        Tere tere tere mou to bay ko mou guiss<br>
        [Laisse-le, qu’il voie par lui même la vérité]<br>
        `,
        story_fr:
        `
        Bonjour à toi l’ami(e) pirate, qui tient ce livret entre tes mains. Je voudrais partager avec toi l’histoire d’un voyage, un rêve créatif, musical et humain, qui a donné naissance à cet album, Ndeye San, et à I-Science, tels que nous sommes aujourd’hui : des pirates, assoiffés de liberté, d’alternatives, d’égalité réelle, comme les pirates de jadis, premiers rêveurs anarchistes !
        Ndeye San, concept difficile à traduire : une exclamation en Wolof rapportant à ce qui touche le cœur. Une belle action. Une belle musique. Une belle personne. Ndeye san peut aussi exprimer la compassion, par exemple, si on dit : « Un
        tel a eu une difficulté », la réponse peut être : « oooh, ndeye san ! ». Ndeye san implique l’ouverture du cœur et I-Science, avec cet album, espère justement pouvoir faire « coucou » à ton cœur !
        Le voyage des pirates scienceurs à la découverte de Ndeye San - l’Océan du Rêve et du Cœur - commence il y a quatre ans. Au départ il y avait deux capitaines à bord, puis un jour un vaisseau spatial a atterri sur le ponton de notre galion et un des capitaines est parti avec des aliens pour découvrir l’espace inconnu.
        Un au-revoir, beaucoup d’émotions...
        Peur de ne pas réussir à tenir la barre, avoir la force, être à la hauteur...au début ce fut le vide, le doute, la confusion, la nuit...ensuite des grosses gouttes de basse, une rythmique crescendo et les voix des sirènes nous ont guidées parmi les vagues sombres... « En avant toutes ! », vers la nuit et ses mystères...I-Lila...
        `,
        story_eng:
        `
        Welcome, pirate friend, as you hold this booklet in your hands. I would like to share with you a story: the creative, musical, dream-like and human journey, which gave birth to this album, Ndeye San. We are I-Science, freedom-thirsty pirates, seeking for alternatives and real equality, just like old-times pirates did, the first anarchist dreamers!
        Ndeye San, a difficult concept to translate. Ndeye San is a Wolof exclamation referring to things or situations warming up one’s heart: a moving action, a beautiful music, a touching person. Ndeye san can also express compassion, for example, if one says, “Such and such experienced a difficulty,” the answer may be, “oooh, ndeye san! ». Ndeye san implies opening one’s heart and I-Science with this album hopes indeed to touch your heart.
        Our journey on the way to discover Ndeye San - the Vast Ocean of Dreams and Heart - began four years ago. Initially there were two captains on board, then one day a spaceship landed on our galleon’s pontoon and one of the captains set off with aliens to explore the unknown through infinite space.
        A farewell carrying so many emotions...
        Fear of not being able to steer the vessel, have the strength, live up to the expectations...at first it was emptiness, doubt, confusion, the night...then, suddenly, large bass bubbles, a crescendo rhythm and the sirens’ voices guided us through the dark waves... “Full steam ahead!” towards the night and its mysteries... I-Lila...
        `,
        bg: bg1,
    },
    {
        id: 1,
        pX: 55,
        pY: 20,
        iconURL: track2,
        spin: false,
        spinSpeed: 0,  
        url: null,
        title: 'Tic Tac',
        lyrics : `
        Stop the clock blocking my mind knock off the rock from my life book, fuck !<br>
        I won’t let my future rot<br>
        I’m not a lab rabbit with a bad habit I’m a bad habit with a rabbit in my hat Sing sing sing<br>
        And the dog still stands in the middle of the street<br>
        Only checking at his own two feet Whilst the cars are passing by<br>
        But I and I won’t be sitting<br>
        Only listening to a beat<br>
        Whilst the time is passing by<br>
        And the time is passing by<br>
        Why, why why ?<br>
        Tic tac tic-tic-ti-tic tac, time is passing by, why ? X2<br>
        The clock is ticking, the clock is ticking <br>And I’m scared of hitting rock bottom <br>If I don’t get working<br>
        Let my job blossom<br>
        Get a move on, get a move on<br>
        Cause no one will be waiting<br>
        And God is only helping<br>
        The ones who help themselves<br>
        Better not keep ideas up in the shelf <br>The weather is hot<br>
        And the egg will rot<br>
        If you don’t cook it up<br>
        Time is tough<br>
        And the road rough enough<br>
        For me to get buff<br>
        Gatt saff, chewing on some gerte thiaff <br>And if time is passing<br>
        I’m also coming past<br>
        with a bobo ras<br>
        Better get working<br>
        fast fast, fast fast, Fast fast yeah<br>
        Le temps il passe le temps il vole<br>
        Un beau matin tu te réveilles<br>
        En te demandant ce qui compte vraiment<br>
        Ce n’est surement pas que l’oseille<br>
        `,
        story_fr: `
        Passer de la nuit au petit matin fut un voyage dur et extraordinaire à la fois. On voulait avancer au plus vite sur les mers de la création mélodique, oubliant qu’il est important d’observer les courants et les vents présents pour bien avancer.
        On a ramé ! Pas assez de vent pour hisser les voiles et la contrainte du temps – monstre marin légendaire – à nos trousses. Mais comme tout bon monstre légendaire, le temps n’a que le pouvoir qu’on veut bien lui donner : quand on cesse de croire en lui, pouffff...il s’estompe.
        ...La rythmique joue avec le temps, le marque avec des coups légers, fugaces mais précis, qui se transforment sur le fil de l’eau, de la mélodie...on ne peut jamais forcer les choses...tic, tac, tic, tac`,
        story_eng: `
        The journey from night to early morning was hard and extraordinary in itself. We wanted to progress as quickly
        as possible on the seas of melodic creation, forgetting the importance of observing present currents and winds in order to move forward constructively.
        We rowed and strived! Not enough wind to hoist the sails and Time, a legendary sea monster, at our pursuit. Yet, as for every good old legendary monster, Time nourishes itself with power taken from us: when we stop believing in it, poof... it fades away.
        ...Rhythm plays with time, marking it with light, fleeting but precise strokes, which are continuously transformed as melody unfolds...you can never force things...tic, tac, tic, tac...
        `,
        bg: bg2,
    },
    {
        id: 2,
        pX: 32,
        pY: 35, 
        iconURL: track3,
        spin: true,
        spinSpeed: 0.005,
        url: null,
        title:'Burning',
        lyrics :`
        The direction we take<br>
        Pushing us down<br>
        We don’t seem to be seeing<br>
        What drives us around<br>
        I don’t know what to say<br>
        In front of consumerism<br>
        I don’t know what to say<br>
        Take the planet down, ah !<br> 
        Cause we’re losing our minds 
        <br>Yes we’re losing our minds<br> 
        Oh we’re losing our minds 
        <br>Say we’re losing our minds<br>
        Cause people are burning and burning <br>and burning and burning, say we’re all burning<br> 
        We’re burning inside<br>
        The world is burning<br>
        Watching it burn in streaming<br> 
        Witnessing death like it is a normal thing Like<br>
        Po-po-po-police, shooting,<br>
        Young Black men in places like Ferguson<br> 
        Them dis-united States still blocked in segregation<br>
        Sacred trinity is :<br>
        Money, power, gain game, bling bling<br> 
        You better listen me a tell you one thing<br> 
        You’re cheating yourself if you think the game is gonna last<br>
        Cheating yourself, the masquerade’s starting to blast<br>
        Wolf dressed as sheep<br>
        Showing his teeth<br>
        What do you think that this crisis is?<br>
        How would you define what terror is?<br>
        The terrorist is the one who fucks with your brain<br>
        Who knows how to manipulate the fear game<br>
        The media’s the main medium of mass manipulation<br>
        Cause people are burning and burning <br>and burning and burning<br>
        Say we’re all burning<br>
        We’re burning inside<br>
        I pray for this world, pray for this world <br>We’re all burning inside<br>
        The prophets a say, prophets a say 
        <br>Armageddon time is coming now<br>
        Them a go a blow up and bust up the brain<br>
        Them a wicked lies go black up I&I eye<br> 
        Man a man can’t meditate, grow, look within<br>
        Too much pollution, the air is getting thin<br>
        `,
        story_fr: `Quand la tempête arrive, on doit affaler les voiles et garder le cap, pour éviter de la prendre de plein fouet. Cependant les pirates sont des fois (souvent) têtus. On n’écoute pas toujours le besoin de cultiver la patience et la résilience, de prendre le temps de comprendre et d’apprendre.
        Au début c’est souvent la colère qui prend le dessus. On a aboyé férocement contre la mer, mouillés jusqu’aux os, en pointant du doigt tout ce qui ne marchait pas, les autres,
        le monde extérieur, ce qu’on trouvait injuste. En brûlant
        de l’intérieur, on voulait changer cet extérieur qui ne nous convenait pas...ensuite on a remarqué que des mots féroces chuchotés sont plus puissants que des mots vides hurlés... mélodiquement...drum n bass...dans un jeu d’intensité... Burning...`,
        story_eng: `When a storm arrives, we have to lower the sails and stay on course to avoid being hit hard. However, pirates are sometimes (often) stubborn. They don’t always listen to the need to cultivate patience and resilience,
        to take the time to understand and learn.
        At first anger often takes over. We barked ferociously at the sea, wet to the bone, pointing our fingers at all the things that didn’t work, at others, at the outside world, at what we felt was unfair. Burning from the inside, we wanted to change this outside world that didn’t suit us...then we noticed that whispering fierce words is more powerful than shouting`,
        bg: bg3,
    },
    {
        id: 3,
        pX: 10,
        pY: 85, 
        iconURL: track4,
        spin: false,
        spinSpeed: 0, 
        url: null,
        title:'True Self',
        lyrics :`
        I left a dream in a closet<br>
        So many languages I know<br>
        But not even one that I master<br>
        In so many rivers I grow<br>
        But not even one that I foster<br>
        She, seems to be saying <br>whilst the river flows way past her<br>
        She, seems to be saying <br>whilst the current is getting faster<br>
        Where is my true self ? x4<br>
        Am I someone else or am I someone new? <br>When I’m on a high everything falls into place but<br>
        When I’m on a low I can’t even stand my own face<br>
        The mirror becomes a caricature <br>And I just wish I was more mature <br>Just enough to know I’m aging <br>Just enough to stop raging<br>
        For things I know I can’t change <br>Just enough to know it’s not strange To be strange<br>
        Where is my true self ? x4<br>
        `,
        story_fr: `En sortant de la tempête, fatigue et adrénaline à la fois. On est encore vivants ! Ca rit, ça pleure, ça se remémore les instants intenses, parfois une bouteille de rhum à la main. Les pirates d’I-Science aiment discuter, analyser, refaire le monde.
        L’importance de la lutte pour l’évolution ...mais...la transformation profonde vient de l’intérieur, c’est de là seulement qu’elle peut jaillir vers l’extérieur. Quand la colère laisse place au questionnement surgit la nécessité de chercher des réponses à l’intérieur de soi.
        C’est là, qu’au coucher du soleil, un par un, nous sommes tous rentrés dans la cabine, nous nous sommes regardés dans le miroir et les mêmes questions ont surgi : « qui suis-je réellement ? Quelles sont mes forces et mes faiblesses ? Mes anges, mes démons ? » Un des pirates a donc pris un tambour et a commencé à taper un rythme répétitif, hypnotisant, le clavier y a ajouté des notes douces de carillon...chacun a commencé à exprimer son vrai moi...intimement...à travers le son...True Self...`,
        story_eng: `We eventually got out of the storm, tired and full of adrenaline. We’re still alive! We laughed, cried, reminisced the most intense moments, sometimes accompanied by a bottle of rum. I-Science pirates like to discuss, analyze, remake the world.
        The struggle for growth is necessary...but...deep transformation comes from within, from there and there only can it flow outwards. When anger gives way to questioning, the need to find answers from within arises.
        It is at that point, during sunset, that one by one, we all went into the cabin, looked at ourselves in the mirror and the same questions came up: “Who am I really? What are my strengths and weaknesses? My angels, my demons? “. One of the pirates took a drum and started a repetitive, hypnotic rhythm, the keyboard added gentle carillon notes...everyone started to express their true self...intimately...through sound...True Self...`,
        bg: bg4,
    },
    {
        id: 4,
        pX: 36,
        pY: 72, 
        iconURL: track5,
        spin: false,
        spinSpeed: 0,
        url: null,
        title:'Lullaby',
        lyrics :`
        After so many, so many years I just realized,<br> 
        I just realized I’d been lacking of self love<br>
        Too hard on my own self<br>
        My heart constantly constrained by my brain<br> 
        Love thy neighbor as thyself<br>
        But how can you love thy neighbor<br>
        If you don’t love yourself?<br>
        Lullaby, lullaby to my soul I’m dedicating this,<br> 
        I Lullaby, lullaby to my soul I’m dedicating this,<br> 
        I Lullaby, lullaby to my soul I’m dedicating this,<br> 
        I Lullaby, lullaby to my soul I’m dedicating this,<br> 
        I Emotional dependency<br>
        Is what is pushing me<br>
        Down in a black hole<br>
        Into the multiverse of my soul<br>
        And I need to pick myself up <br>And accept to be alone<br>
        So I can be with others<br>
        So I can be with others<br>
        Lullaby, lullaby to my soul I’m dedicating this, I<br>
        Lullaby, lullaby to my soul I’m dedicating this, I<br> 
        Lullaby, lullaby to my soul I’m dedicating this, I<br>
        Lullaby, lullaby to my soul I’m dedicating this, I<br>
        `,
        story_fr: `Au réveil, le soleil brillait. La lumière filtrant des hublots, on a regardé nos visages : ils avaient changé, ou plutôt on voyait pour la première fois nos vrais visages respectifs, sous nos masques de pirates des mers et des rêves. On s’était retrouvés : enchantée, enchanté, enchantant...
        Après cela, l’étape la plus importante est d’apprendre ou réapprendre à s’aimer, à croire en soi.
        On a jeté l’ancre et sommes descendus sur un ilot pour réapprendre les petites choses essentielles : le sable chaud sous les pieds, l’eau fraîche sur la peau, prendre le temps pour soi, écouter son corps, manger des fruits, s’offrir toutes ces belles sensation par amour de soi. Devenir son propre père, sa propre mère, son/sa propre meilleur(e) ami(e). On s’est assis joyeusement pour écrire une mélodie évolutive, un son avec des trésors cachés, une berceuse pour nos âmes...Lullaby...`,
        story_eng: `When we woke up, the sun was shining. A dim light filtered through the portholes. We looked at our faces: they had changed, or rather, for the
        very first time we could see our real faces, under our dreamscape pirates’ masks. We had found each other again: pleased to meet you, pleased to see you, pleased to sing you...
        The next most important step was to learn or relearn self-love and believing in oneself.
        We anchored and landed on a beautiful little island to relearn the essentials of life: the warm sand under our feet, the cool water on our skin, taking time for ourselves, listening to our bodies, eating fruit, offering ourselves all these beautiful sensations out of sheer self-love. Becoming your own father, your own mother, your own best friend. We sat down joyfully to write an evolving melody, a sound full of hidden treasures, a lullaby for our souls...Lullaby...`,
        bg: bg5,
    },
    {
        id: 5,
        pX: 83,
        pY: 35, 
        iconURL: track6,
        spin: false,
        spinSpeed: 0, 
        url: null,
        title:'Outta Reality',
        lyrics :`
        Look into the depths of my eyes<br>
        I am you and you are I and I<br>
        Find the internal child There to be, there to be Wild, wild, wild<br>
        Guiss guiss<br>
        Look<br>
        Please don’t make it stop Eyes together, <br>Divine, We are the I-niverse <br>Please don’t make it stop <br>It’s Human destiny<br>
        Feel the flow of chi in you <br>Feel the flow of chi in you <br>Feel the flow of chi in you <br>Feel the flow of chi<br>
        `,
        story_fr: `Notre bateau nous a ensuite menés à proximité d’un arc-en-ciel. On dit que cela n’arrive jamais, mais, « ne jamais dire jamais » : on a plongé en plein dedans. Au début chaque membre de l’équipage ne voyait qu’une couleur : qui bleu, qui vert, qui jaune... Ensuite on a pris conscience d’être tous des rêveurs, vivant à l’intérieur de bulles de réalité créés par nous-mêmes, par notre vision. La science de la conscience de l’inconscient : I-Science...
        Nous sommes comme des projecteurs émettant des images, notre réalité, sur la surface de la vie. Si nous voulons créer du changement nous ne pouvons le faire qu’en manipulant le projecteur, pas les images.
        Se transformer, remettre en question les systèmes de croyance préétablis, regarder l’Univers...Langages conscients et inconscients se mêlent, pour créer des poésies sans phrase, faites de mélodies. Tout est remis en question, tout est possible. Les instruments parlent entre eux, se posent des questions, se répondent, virevoltent et se cachent...rêve d’une création surréaliste...Outta Reality...`,
        story_eng: `Riding the waves, our boat carried us next to a rainbow. They say it’s impossible but “nothing is impossible”: we dived right into it. At first each pirate could only see one colour: some saw blue, others green, red or yellow... Then we realised we were all dreamers, living inside reality bubbles created by our very own selves, by our vision. Consciousness of the unconscious is science: I-Science...
        We are like video-projectors emitting images, our reality, onto the surface of life. If we want to create change we can only do it by manipulating the projector, not the images.
        Transforming oneself, questioning pre-established belief systems, looking at the Universe...Conscious and unconscious languages blend together to create poetry without words, made out of melodies. Everything is questioned, everything is possible. Instruments talk to each other, ask questions, give answers, twirl and hide...dream of a surrealist creation...Outta Reality...`,
        bg: bg6,
    },
    {
        id: 6,
        pX: 50,
        pY: 45, 
        iconURL: track7,
        spin: false,
        spinSpeed: 0, 
        url: null,
        title:'Cycles',
        lyrics :`
        Live, die, live, die, live, die, live Live, live, live, live<br>
        So many cycles<br>
        In one life<br>
        Shifting<br>
        So many cycles<br>
        We go through<br>
        Oh up and down is rotating the wheel of life<br>
        And we’re obliged to go through good and bad times So be it, be it, be it, be it<br>
        We must learn to accept it<br>
        REF<br>
        First it is light<br>
        And then<br>
        Then oh you feel the cold<br>
        Of everything around you<br>
        And you breathe<br>
        You breathe in my son<br>
        And you live<br>
        You live my son<br>
        So many cycles<br>
        In one life<br>
        Shifting<br>
        So many cycles<br>
        We go through<br>
        An you learn with life<br>
        My son<br>
        And I feel<br>
        That we’re gonna go forward<br>
        Bathetha kanjan mamela <br>(How do they speak ? Listen)<br>
        Mama wobomi onokwenza kanje <br>(Mother nature only, can do such)<br>
        Mdali yed’onamandl’anje khani vume <br>(It’s her who has the power, it is so)<br>
        Awen’onendlebe mamela <br>(You with ears, Hear them)<br>
        Mandla wengxaki sithataha kuwe <br>(Power to overcome hardships, we get from you)<br>
        Asithati sicela kuwe kanicule <br>(We don’t take but ask, sing)<br>
        Awen’onamehlo khangela <br>(You with eyes, Look) <br>Mendo wendlela wanyukelela (Journey’s union is an uphill)<br>
        sesilapha sikhatshwa nguwe khaningqungqe <br>(We are here now, you accompany us, dance and sing) <br>Bangen’elizweni bangena (Into this world they enter)<br>
        Khankatha nkonde mhlekaz ommhle <br>(Guide Keeper of the Ancient, you Revered One)<br>
        Poph’ amanxeb’ usiphulule nje masiphile <br>(Dress the wounds and massage them, Let us heal)<br>
        Ah wena wemveli bayethe <br>(You Indigenous One, we bowe)<br>
        Qamata mdali wabantu bonke <br>(Divine Creator, you Creator of everyone)<br>
        mdalw’obomi nezinto zonke sicel’usithamsanqele <br>(You Creator of life and all, we plead for you to bless us)<br>
        bevel’emazweni bevela <br>(From far away lands, they come)<br>
        baw’elemlanjeni bawela <br>(Crossing the river, they come)<br>
        bamweze’meveni bamweza <br>(Crossing thorny paths, they come)<br>
        hlway’emyezweni hlwayela <br>(Sow seeds in paradise, Sow)<br>
        `,
        story_fr: `Se laisser bercer par les vagues...Oh pirates ! Que c’est bon ! Oh pirates ! Que c’est dur !
        Quand une vague te prend, laisse-toi aller, suis le mouvement en restant fluide, car quand t’es rigide, la vague tape dur. Cultiver comme une fleur l’acceptation, l’accueil de ce qui vient. Chaque marée, dure ou douce, est dans l’ordre des choses, une expérience, une leçon sur le cap vers ce que nous serons.
        Comme les vagues de la mer, la Vie est ainsi faite de cycles. De la nuit, au jour, de la vie à la mort, et de la mort, une nouvelle fois à la vie. On
        a festoyé, pleuré, rit et pensé sur ces vagues...on a joué avec des pirates venus d’ailleurs...un ami pirate est venu nous dire au revoir, sur cette création, avant de se transformer en ancêtre...bon voyage Odwa Shweni, mon ami...que ta Lumière et Sagesse puissent briller partout dans l’Univers...Cycles`,
        story_eng: `When a wave catches you, let yourself go, go with the flow, remain fluid, because when you are rigid, that’s when it hurts. Cultivate acceptance, the welcoming of what comes, as you would with a flower. Each tide, hard or soft it may be, is in the order of things, an experience, a lesson, on the path towards what we shall be.
        Like waves in the sea, Life is thus made of cycles. Night followed by day, life followed by death and death followed by life again. We feasted, cried, laughed and meditated on these waves...we played with pirates from all horizons...a pirate friend came to say goodbye, on this creation, before turning into an Ancestor...bon voyage Odwa Shweni, my friend...may your Light and Wisdom shine in all places of the Universe...Cycles`,
        bg: bg7,
    },
    {
        id: 7,
        pX: 72,
        pY: 12, 
        iconURL: track8,
        spin: true,
        spinSpeed: 0.0013,
        url: null,
        title:'Fadjar',
        lyrics :`
        After rain there is sunshine<br>
        As I am walking by the sea<br>
        with my feet in the sand <br>I reminisce of them hard moments<br>
        And know that there’s a lesson to be learnt<br>
        I believe<br>
        That after rain there is sunshine<br>
        `,
        story_fr: `L’éclaireur a crié : « lumière à l’horizon ! » et nous avons pris la barre, vers de nouvelles aventures. La brise de mer laissait la place au ciel découvert. Une nouvelle aube se dessinait. En regardant en arrière on s’est rendu compte que chaque épreuve portait un enseignement, une clé permettant d’ouvrir les portes suivantes.
        Les expériences passées ouvrent la porte à la transformation permettant de voir les choses sous un autre jour. On jette les binocles. Nos yeux nous suffisent. Devant nous le champ de toutes les possibilités, devant nous l’océan, le nouveau soleil du petit matin, qui brille...une guimbarde rit avec une guitare : elles se racontent des histoires de pirates, créant des spirales magiques, dansant des mélodies mystiques...Fadjar...`,
        story_eng: `The scout shouted: “Light on the horizon! “...and we steered the vessel,
        off to new adventures. The rain gave way to open skies. A new dawn was coming. As we looked back, we realized each ordeal carried a lesson, a key to open coming doors.
        Past experiences open the door to transformation, allowing us to see things under a different light. Throw away binoculars! Our eyes are sufficient! In front of us the scope of all possibilities, in front of us the ocean, the rising sun, shining...a jaw harp laughs with a guitar: they share pirate tales, create magic spirals, dance to mystical melodies...Fadjar`,
        bg: bg8,
    },
    {
        id: 8,
        pX: 15,
        pY: 12, 
        iconURL: track9,
        spin: true,
        spinSpeed: 0.0015,
        url: null,
        title: 'Purification',
        lyrics :`
        Mame boy nena adouna bi daffa toukki <br>[Grand-mère a dit : la vie touche à sa fin]<br>
        Mame nena ci dieumi diamono leniou tolloou nii <br>[Grand-père a dit : nous sommes dans l’ère du temps]<br>
        Baaye nena adouna ci yakaar amoutoul fi <br>[Père a dit : dans cette vie il n’y a pas d’espoir]<br>
        Yaaye nena ngor mome diaratoul fi <br>[Mère a dit : il n’y a plus de dignité]<br>
        Doom nena fo que ma toukki <br>[Fils a dit : il faut que je parte]<br>
        Ndakh ligueey mome amoul fi <br>[Car ici il n’y a pas de travail]<br>
        Doom nena djiko yi la nou wara sopi <br>[Fils a dit : nous devons changer nos habitudes]<br>
        Purification, purificate, unification, unificate, <br>Irification, Irificate, purification oh Lord, purificate <br>Everyone love yourself<br>
        You must purificate yourself<br>
        You will learn how to be free now<br>
        You mustn’t forget that <br>Everything in you<br>
        Is a mirror to the Universe<br>
        And you are the Universe <br>And the Universe is you<br>
        Irificate your Soul<br>
        Cause you are holy<br>
        You’re gonna find what is best in your life Your life<br>
        Purification<br>
        La Illaha Illallahou<br>
        `,
        story_fr: `Tout à coup nous arrivons dans un endroit chaud, très chaud. D’où vient cette chaleur ? Mais...elle vient de nous ! Pirates, cultiver l’amour de soi nous a appris à aimer ce qui nous entoure. Envie de servir un dessein plus grand, de contribuer au bien-être universel. Des pirates en mission, menés par la joie d’avoir un but qui ne ternit jamais, au-delà du soi.
        La parole est magie. La musique aussi. Ce qui sort de nous a le pouvoir de faire du bien ou du mal. Guérir les autres avec ses paroles et la musique. Se laver des poids, des pensées et visions négatives, s’ouvrir au positif,
        le partager autour de soi, cela nettoie...mélodies joyeuses, montée en intensité, des voix qui se mêlent pour créer une harmonie du cœur... Purification...`,
        story_eng: 'Suddenly we arrive in a warm, very warm place. Where does this heat come from? But...it comes from us! My friend, cultivating self-love taught us to love what surrounds us. The need to serve a greater purpose, to contribute to universal well-being. Pirates on a mission, driven by the joy of having a purpose that never tarnishes, beyond the self. Words are magic. So is music. Whatever comes out of us has the power to do good or harm. Healing others with words and music. Cleansing oneself of weights, negative thoughts and visions, opening up to positivity, sharing it around us...purification processes...joyful melodies, rising in intensity, voices blending together to create harmonies from the heart... Purification',
        bg: bg9,
    },
    {
        id: 9,
        pX: 10,
        pY: 60, 
        iconURL: track10,
        spin: false,
        spinSpeed: 0, 
        url: null,
        title:'Dieuf Dieul',
        lyrics :` 
        We’re looking upon, we’re looking upon <br>[nous faisons face, nous faisons face]<br>
        The kingdom <br>[au royaume]<br>
        Of ego and cheats and lies <br>[de l’ego, des arnaques et des mensonges]<br>
        Oh no, my friend <br>[oh non, mon ami]<br>
        What the world come to ? <br>What the world come to ? <br>[Où va le monde ?]<br>
        I need to change galaxy <br>[je dois changer de galaxie]<br>
        And we’re wandering still <br>[et on se demande encore]<br>
        How we’re gonna <br>[comment va t’on faire]<br>
        How we’re gonna survive, survive <br>[pour survivre]<br>
        
        So dokhé ci yoonou adouna <br>[Quand tu marches sur le chemin de la vie]<br>
        Nga xam que dara yomboul <br>[Tu dois savoir que rien n’est facile]<br>
        Nga wara doorwaar pour am li nga beugg <br>[Il faut travailler dur pour avoir ce que l’on veut]<br>
        Sa yene moi sa lamp <br>[Ton intention est ta lampe]<br>
        Bou la leral <br>[Qui t’éclaire]<br>
        Ci biirou yoon bi <br>[Sur le chemin]<br>
        Guemmal sa bopp <br>[Croie en toi]<br>
        Yangui dokh ak Yallah <br>[Car tu marches avec Dieu]<br>
        
        Yangui dokh ak Yallah x3<br>
        
        Dieuf Dieul, di de flou bax am lou bax <br>[Cueillir ce que l’on sème, si tu fais du bien tu auras du bien]<br>
        Dieuf Dieul, di def lenen nga am lenen <br>[Cueillir ce que l’on sème, si tu fais autres chose, tu auras autre chose]<br>
        
        Sometimes in my life <br>[des fois dans ma vie]<br>
        My ego serves me pain and strive <br>[mon ego me crée beaucoup de difficultés]<br>
        But i strike back by searching <br>[mais je réponds en recherchant]<br>
        What the root of the problem is <br>[quelle est la racine du problème]<br>
        I go and look <br>[je vais regarder]<br>
        Within me <br>[à l’intérieur de moi-même]<br>
        
        So dokhé ci yoonou adouna <br>[Quand tu marches sur le chemin de la vie]<br>
        Nga xam que dara yomboul <br>[Tu dois savoir que rien n’est facile]<br>
        Nga wara doorwaar pour am li nga beugg <br>[Il faut travailler dur pour avoir ce que l’on veut]<br>
        Sa yene moi sa lamp <br>[Ton intention est ta lampe]<br>
        Bou la leral <br>[Qui t’éclaire]<br>
        Ci biirou yoon bi <br>[Sur le chemin]<br>
        Guemmal sa bopp <br>[Croie en toi]<br>
        Yangui dokh ak Yallah <br>[Tu marches avec Dieu]<br>
        
        Yangui dokh ak Yallah x3<br>
        
        Dieuf Dieul, di de flou bax am lou bax <br>[Cueillir ce que l’on sème, si tu fais du bien tu auras du bien]<br>
        Dieuf Dieul, di def lenen nga am lenen <br>[Cueillir ce que l’on sème, si tu fais autres chose, tu auras autre chose]<br>
        
        `,
        story_fr: `Purifiés, le vent en poupe, la brise marine emplissant nos narines et poumons ! 
        Comme un seul homme/femme on crie : à l’abordaaassss !!!! On hisse l’ancre et c’est parti ! 
        On est forts, lucides, on est ensemble et on est si forts car on est ensemble ! Instant précieux 
        de lucidité : la séparation avec ce qui nous entoure n’est qu’illusion. Il y a un lien continu et infini avec chaque être, chaque chose.
        Œuvrons ensemble pour créer quelque chose de nouveau, une réalité alternative, proche de ce qui 
        fait vibrer nos cœurs. Nous récoltons ce que nous semons (dieuf dieul en Wolof). 
        Oui, une autre réalité est possible. Une vision singulière, manière différente de se rapporter, 
        pour nourrir le lien...le clavier et la voix, par exemple, se sentaient seuls, tous les autres 
        instruments sont donc venus leur donner main forte pour créer un son puissant, un son pour avancer, un son de pirates...
        Dieuf Dieul...
        `,
        story_eng: `Purified, the wind in our sails, the sea breeze filling our nostrils and
        lungs! As one man/woman we shout: “Pirates, All Aboard!!!” We weigh the anchor and off we go! We are strong, lucid, we are together and we
        are so strong because we are together! Precious moment of lucidity: the separation with everything surrounding us is only illusion. There is a continuous and infinite link with each being, each thing.
        Let us work together to create something new, an alternative reality, closer to what makes our hearts vibrate. We reap what we sow (dieuf dieul in Wolof). Yes, another reality is possible. A singular vision, a different way of relating to others, to nourish the link...keyboard and voice, for example, felt alone, so all the other instruments came to help them create a powerful sound, a sound to move forward, a pirates’ sound...Dieuf Dieul.
        `,
        bg: bg10,
    },
]


export default Data;