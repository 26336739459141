import {Sprite} from 'pixi.js'
import {Loader} from 'pixi.js'
import * as PIXI from 'pixi.js'
import gsap from 'gsap'
import PixiPlugin from 'gsap/PixiPlugin'
import Data from "./AssetsData.js"
import pointerdown_webm from '../assets/audio/success_.webm'
import pointerdown_mp3 from '../assets/audio/success_.mp3'
import onHover_SFX_webm from '../assets/audio/onHover_SFX.webm'
import onHover_SFX_mp3 from '../assets/audio/onHover_SFX.mp3'
import SFX from "./SFX"

gsap.registerPlugin(PixiPlugin);
PixiPlugin.registerPIXI(PIXI);

let onHover_SFX = new SFX(onHover_SFX_webm, onHover_SFX_mp3, 0.05);
let pointerdown_SFX = new SFX(pointerdown_webm, pointerdown_mp3, 0.2);

class Symbol {
    constructor(texture, container, trackID){

        this._sprite = new Sprite(Loader.shared.resources[texture].texture);
        this._sprite.anchor.x = 0.5;
        this._sprite.anchor.y = 0.5;

        this._sprite.scale.x = this.setScale();
        this._sprite.scale.y = this.setScale();
        this._sprite.cursor = 'pointer';

        this.alreadyOpened = false;
        this.assetType = 'symbol';

        this.pulse= new PIXI.Graphics();
        this.pulse.lineStyle(1, 0xcfb278, 1);  
        this.pulse.drawCircle(0,0,80);
        this.pulse.scale.set(0,0);
        this.pulse.endFill(); 
        container.addChild(this.pulse); 

        container.addChild(this._sprite);
        this._sprite.interactive = true;
        this.onClick();
        this.onHover();
        this.trackID = trackID; 

    }

    // On desktop: when the mouse is over the sprite
    onHover(){
        let duration = 0.2;
        this._sprite.on("mouseover", ()=>{  
            onHover_SFX.play();
            gsap.to(this._sprite,{
                pixi: {
                    scaleX: 0.9, scaleY: 0.9
                },
                duration: duration,
            });

            gsap.from(this.pulse,{
                pixi: {
                    alpha: 1,
                    scaleX:0,
                    scaleY:0,
                },
            });

            gsap.to(this.pulse,{
                pixi: {
                    alpha: 0,
                    scaleX:2,
                    scaleY:2,
                },
                duration: 1.2, 
            });

        });
        this._sprite.on("mouseout", ()=>{  
            gsap.to(this._sprite,{
                pixi: {
                    scaleX: this.setScale(), scaleY: this.setScale()
                },
                duration: duration,
            });
        });
    }

    setContent(data, storyHtmlID, lyricsHtmlID){
        document.getElementById(storyHtmlID).innerHTML = `<p>` + data[this.trackID].story_fr + `</p><button id="showPlayer" class="standard_btn"><i style="margin-right: 1em;" class="fas fa-play"></i>Ecouter un extrait</button>`;
        document.getElementById(lyricsHtmlID).innerHTML = data[this.trackID].lyrics;
    } 


    // On mobile & Desktop
    onClick(){
        this._sprite.on("pointerdown", ()=>{  

            pointerdown_SFX.play();

            document.getElementById("content-wrapper").style.backgroundImage = 'url(' + Data[this.trackID].bg + ')';

            let duration = 0.85;
 
            gsap.fromTo("#content-wrapper", {y: window.innerHeight}, {y: 0, duration: duration, ease: "expo"});

            document.getElementById("content-wrapper").style.display = "initial";

            document.getElementById("track_id").innerHTML = this.trackID + 1;
            document.getElementById("track_name").innerHTML = Data[this.trackID].title;
            document.getElementById("track_icon_img").setAttribute('src', Data[this.trackID].iconURL);

            this.setContent(Data, "track_story_text", 'track_lyrics');

            if (document.getElementById('track_story').style.display == 'none') {
                document.getElementById('track_story').style.display = 'flex';
                document.getElementById('track_player').style.display = 'none';
            }

            if(document.getElementById("showPlayer") != null || document.getElementById("showPlayer") != undefined){
                document.getElementById("showPlayer").addEventListener('click', ()=>{
                    document.getElementById('track_story').style.display = 'none';
                    document.getElementById('track_player').style.display = 'flex';
                });
            }

            //Display only the concerned ID for the player
            document.getElementById('howl_player_' + this.trackID).style.display = 'initial';
            //Display only the concerned ID for the player
            document.getElementById('progress-bar-' + (this.trackID)).style.display = 'block';
        });
    }

    setPosition(background, pX, pY){  
        // P represents the percentage not the actual 
        // position in pixels (just an estimation)
    
        let newPos = {
            x: background.width * (pX/100),
            y: background.height * (pY/100)
        } 
    
        this._sprite.x = newPos.x;
        this._sprite.y = newPos.y;
        
        this.pulse.x = this._sprite.x;
        this.pulse.y = this._sprite.y; 
    }

    setScale(){
         return 0.7;
        
    }

    spin(isSpinning, speed){
        if (isSpinning) {
            this._sprite.rotation += speed;
        }
    }

    clamp(num, min, max){
        // Clamp number between two values with the following line:
        return Math.min(Math.max(num, min), max);
    }

    updateSize(ratio){
        this._sprite.scale.x = this._sprite.scale.x - (ratio * 0.01);
        this._sprite.scale.y = this._sprite.scale.y - (ratio * 0.01);
    }

    
 
}

export default Symbol;